<template>
  <b-card class="mt-1">
    <!-- filter form -->
    <form-generator v-if="hasFilter"
      :schema="filter_schema" :model="query"
    />
    <div class="d-flex mb-1">
      <data-create-popup v-if="hasCreatePopup && write_permission" class="ml-auto" ref="create_popup"
        :default-data="{...item_default_data}"
        :create-data-fn="createRowFn" :schema="create_schema"
        @create="getList"
        :buttonText="create_button_text"
      />
    </div>
    <!-- end filter form -->
    <vue-good-table :id="id" :mode="mode"
      :columns="tableFields"
      :rows="rows"
      :sort-options="{
        enabled: sort,
      }"
      :pagination-options="{
        enabled: pagination,
        perPage:limit
      }"
      @on-page-change="changePage"
      @on-sort-change="colSort"
      @on-column-filter="colFilter"
      @on-per-page-change="changeLimit"
      :totalRows="totalRows"
      :isLoading="isLoading"
      styleClass="vgt-table striped condensed bordered"
    >
      <!-- col -->
      <template slot="table-row" slot-scope="props">
        <!-- actions -->
        <span v-if="props.column.type === 'actions'" class="d-flex justify-content-end">
          <!-- inline-edit -->
          <div v-if="isRowEditing(props.row)" class="d-flex">
            <b-button class="btn-icon mr-50" variant="flat-success"
              title="Save this row."
              @click="editRow(props.row)"
            >
              <b-spinner v-if="editing_rows[props.row.originalIndex].is_loading" small />
              <feather-icon v-else icon="CheckIcon"/>
            </b-button>
            <b-button class="btn-icon mr-50" variant="flat-secondary"
              v-if="!editing_rows[props.row.originalIndex].is_loading"
              title="cancel."
              @click="cancelEditRow(props.row)"
            >
              <feather-icon icon="XIcon"/>
            </b-button>

          </div>
          <div v-else class="d-flex">
            <b-button v-if="!!toEditFn" class="btn-icon mr-50" variant="flat-info" title="To edit page"
              @click="toEdit(props.row)"
            >
              <feather-icon icon="EditIcon"/>
            </b-button>

            <b-button v-if="!!editRowFn && !hasEditPopup && write_permission" class="btn-icon mr-50" variant="flat-warning"
              @click="toggleEditingRow(props.row)"
            >
              <feather-icon icon="EditIcon"/>
            </b-button>

            <b-button v-if="hasEditPopup && write_permission" class="btn-icon mr-50" variant="flat-primary" size="sm"
              title="Open edit popup" @click="$refs.update_popup.startEdit(rows[props.row.originalIndex], { index: props.index })"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <!-- inline-edit end -->

            <delete-button v-if="!!deleteRowFn && write_permission" class="btn-icon" size="sm" variant="flat-danger"
              title="Delete this row."
              :is-loading="editing_rows[props.row.originalIndex].is_loading"
              :message="`Delete item ${props.row.id || ''}?`"
              @delete="deleteRow(props.row)"
            />
          </div>
          <more-actions v-if="more_actions.length && write_permission" :actions="more_actions" :item="props.row" :index="props.index"
           title="More actions"
          />
        </span>
        <!-- actions end -->
        <span v-else-if="props.column.display && typeof props.column.display === 'function'">
          {{ props.column.display(props.row, { index: props.row.originalIndex, rows }) }}
        </span>

        <span v-else-if="props.column.input_type === 'image'"  v-b-tooltip.hover.left :title="props.row[props.column.field]">
          <b-input v-if="isRowEditing(props.row) && props.column.editable"
            v-model="rows[props.row.originalIndex][props.column.field]"
          />
          <img v-else :src="props.row[props.column.field]" :alt="props.row[props.column.field]" style="max-width: 90px">
        </span>

        <span v-else-if="props.column.input_type === 'avatar'"  v-b-tooltip.hover.left :title="props.row[props.column.field]">
          <b-avatar :src="props.row[props.column.field]" rounded="sm" />
        </span>

        <template v-else-if="props.column.input_type === 'multiselect'">
          <multi-select v-if="isRowEditing(props.row) && props.column.editable"
            :id="id + props.column.field"
            :options="props.column.options"
            v-model="rows[props.row.originalIndex][props.column.field]"
          />
          <span v-else>
            <option-tag v-for="(item) in props.row[props.column.field]" :key="item" variant="info" class="mr-50"
              :options="props.column.options" :key_value="item"
            />
          </span>
        </template>
        <template v-else-if="props.column.input_type === 'select'">
          <multi-select v-if="isRowEditing(props.row) && props.column.editable" :multiple="false"
            :id="id + props.column.field"
            :options="props.column.options"
            v-model="rows[props.row.originalIndex][props.column.field]"
          />
          <span v-else>
            <option-tag v-if="props.row[props.column.field] !== null" class="mr-50"
              :options="props.column.options" :key_value="props.row[props.column.field]"
            />
          </span>
        </template>

        <template v-else-if="props.column.input_type === 'datetime'">
          <datetime-picker v-if="isRowEditing(props.row) && props.column.editable"
            :config="{ enableTime: true, altFormat: 'd/m/Y, H:i:S', dateFormat: 'Z', }"
            :place-holder="props.column.label" v-model="rows[props.row.originalIndex][props.column.field]"
          />
          <span v-else>
            {{props.row[props.column.field] | local_time_string}}
          </span>
        </template>
        <template v-else-if="props.column.input_type === 'date'">
          <datetime-picker v-if="isRowEditing(props.row) && props.column.editable"
            :place-holder="props.column.label" v-model="rows[props.row.originalIndex][props.column.field]"
          />
          <span v-else>
            {{props.row[props.column.field] | local_date}}
          </span>
        </template>

        <template v-else-if="props.column.display_type === 'translatable-text' || props.column.display_type === 'translatable-richtext'">
          <span @click="props.column.click_event ? fieldClickEvent(props.column.field, rows[props.row.originalIndex]) : () => {}" :class="props.column.click_event ? 'clickable-field': ''">
            {{transformTranslatableText(getProperty(props.row, props.column.field))}}
          </span>
        </template>

        <template v-else-if="props.column.input_type === 'textarea' && isRowEditing(props.row) && props.column.editable">
          <b-form-textarea
            :placeholder="props.column.label"
            v-model="rows[props.row.originalIndex][props.column.field]" rows="3" max-rows="18"
          />
        </template>

        <template v-else-if="props.column.input_type === 'switch'">
          <b-form-checkbox :checked="true" v-model="rows[props.row.originalIndex][props.column.field]" class="custom-control-primary" switch
            :disabled="!isRowEditing(props.row) || !props.column.editable"
            :name="`form_input-${props.column.field}`"
          />
        </template>

        <!-- common -->
        <span v-else-if="isRowEditing(props.row) && props.column.editable">
          <b-input v-if="props.column.input_type === 'number'" type="number"
            v-model="rows[props.row.originalIndex][props.column.field]"
          />
          <b-input v-else
            v-model="rows[props.row.originalIndex][props.column.field]"
          />
        </span>

        <span v-else-if="props.column.value_handler" class="d-inline-block" style="max-width: 600px; min-width: 100%; max-height: 90px; overflow: auto;" :style="styleMethod(props.column,props.row)">
          {{props.column.value_handler(props.row)}}
        </span>
        <span v-else class="d-inline-block" style="max-width: 600px; min-width: 100%; max-height: 90px; overflow: auto;" :style="styleMethod(props.column,props.row)" @click="props.column.click_event ? fieldClickEvent(props.column.field, rows[props.row.originalIndex]) : () => {}" :class="props.column.click_event ? 'clickable-field': ''">
          {{props.row[props.column.field]}}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div v-if="allow_pagination" class="d-flex align-items-center flex-wrap mt-3">
          <div class="d-flex align-items-center mb-0">
            <span class="text-nowrap ">
              Showing
            </span>
            <b-form-select
              v-model="limit"
              :options="['10','20','50','100','200','1000']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of total: {{ props.total }} rows </span>
          </div>
          <b-button v-if="!export_info.is_exporting" size="md" class="btn-icon ml-1" variant="flat-info" @click="exportData"
            v-b-tooltip.hover title="Export Data"
          >
            <feather-icon icon="DownloadIcon" />
          </b-button>
          <b-pagination class="mb-0 ml-auto" first-number last-number align="right"
            :value="1" :total-rows="props.total" :per-page="limit"
            prev-class="prev-item"
            next-class="next-item"
            @input="(value)=>props.pageChanged({currentPage:value})"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
        <div class="mt-1" v-if="export_info.total">
          <label>Exporting: <strong>{{ export_info.completed }} / {{ export_info.total }}</strong></label>
          <b-progress
            :max="export_info.total"
          >
            <b-progress-bar :value="export_info.completed">
            </b-progress-bar>
          </b-progress>
        </div>
      </template>

    </vue-good-table>
    <data-update-popup ref="update_popup" :schema="update_schema" :update-data-fn="popupUpdate" />
  </b-card>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css';
import { VueGoodTable } from 'vue-good-table';
import sheet from '@core/utils/sheet';
import { debounce, omitBy } from '@core/utils/index';

import DataUpdatePopup from '@core/components/data-update-popup/View.vue';
import OptionTag from '@core/components/option-tag/View.vue';
import useWritePermission from '@core/comp-functions/useWritePermission';

const EXPORT_CHUNK_MAX = 180;
const EXPORT_CHUNK_MIN = 9;
import { get } from 'lodash'
export default {
  name: 'data-table-ssr',
  components: {
    VueGoodTable,
    DataUpdatePopup,
    OptionTag,
  },
  props: {
    mode: { type: String, default: 'remote' },
    columns: { type: Array, required: true },
    id: { type: String, required: true },
    sort: { type: Boolean, default: false },
    search: { type: Boolean, default: false },
    pagination: { type: Boolean, default: true },
    limitBase: { type: Number, default: 50 },
    item_default_data: { type: Object, default: () => { return {}; } },
    create_schema: { type: Array, default: () => [] },
    update_schema: { type: Array, default: () => [] },
    filter_schema: { type: Array, default: () => [] },
    create_button_text: { type: String, default: 'Create' },
    query_default: { type: Object, default: () => { return null; } },
    more_actions: { type: Array, default: () => [] },
    allow_export: {type: Boolean, default: true},
    allow_pagination: {type: Boolean, default: true},
    // methods
    getListFn: { type: Function },
    deleteRowFn: { type: Function },
    createRowFn: { type: Function },
    editRowFn: { type: Function },
    toEditFn: { type: Function },
  },
  setup(props, ctx) {
    let { write_permission } = useWritePermission(ctx.root?.$route);
    return { write_permission };
  },
  data() {
    return {
      isLoading: false,
      is_editing: false,
      limit: 50,
      inline_editing: false,
      editing_rows: {},
      rows: [],
      totalRows: 0,
      page: 0,
      query: {},
      export_info: {
        is_exporting: false,
        data: [],
        total: 0,
        completed: 0,
        progress: 0,
      },
    }
  },
  computed: {
    tableFields() {
      let fields = [...this.columns];
      if(this.hasActions) {
        fields.push({ label: 'Actions', type: 'actions', field: '__actions', thClass: 'text-right' });
      }
      return fields;
    },
    hasActions() {
      return !!this.deleteRowFn || !!this.editRowFn || !!this.toEditFn
    },
    authLanguage() {
      return this.$store.getters["auth/language"];
    },
    hasCreatePopup() {
      return !!this.createRowFn && !!this.create_schema.length;
    },
    hasEditPopup() {
      return !!this.editRowFn && this.update_schema.length;
    },
    hasFilter() {
      return !!this.filter_schema.length;
    },
  },
  created() {
    this.getList = debounce(this.getList, 300);

    this.limit = this.limitBase;
    this.getList();
    if (this.query_default){
      this.query = this.query_default
    }
  },
  watch: {
    page() {
      this.getList();
    },
    limit() {
      this.getList();
    },
    query: {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  methods: {
    async getList() {
      if(!this.getListFn) { console.log('no_get-list-fn_for_table:', this.id); return }
      this.isLoading = true;
      let { list, total } = await this.getListFn({
        limit: this.limit,
        page: this.page,
        query: this.query,
      });
      this.rows = list;
      this.totalRows = total;
      this.isLoading = false;
      this.editing_rows = list.map(item => {
        return { is_loading: false, is_editing: false, origin: {...item} }
      });
    },
    getEdittingRow(row) {
      return this.editing_rows[row.originalIndex];
    },
    isRowEditing(row) {
      let editting_row = this.getEdittingRow(row);
      return editting_row.is_editing;
    },
    toggleEditingRow(row) {
      let editing_row = this.getEdittingRow(row);
      editing_row.is_editing = editing_row.is_editing ? false : true;
    },
    async editRow(row) {
      let editting_row = this.getEdittingRow(row);
      editting_row.is_loading = true;
      let success = true;
      await this.editRowFn(this.rows[row.originalIndex]).catch(error => {
        success = false;
      });
      editting_row.is_loading = false;
      if(!success) {
        return;
      }
      this.toggleEditingRow(row);
      this.$emit('editRow', { row });
    },
    cancelEditRow(row) {
      let editting_row = this.getEdittingRow(row);
      if(!editting_row.origin) {
        console.log('editting_row.origin must be object but found: null');
      } else {
        for (const key of Object.keys(this.rows[row.originalIndex])) {
          this.rows[row.originalIndex][key] = editting_row.origin[key];
        }
      }
      this.toggleEditingRow(row);
    },
    async popupUpdate(item_data, { index = null } = {}) {
      let success = true;
      await this.editRowFn(item_data, index).catch(() => {
        success = false;
      });
      if (!success) { return; }
      this.getList();
      this.$emit('rowPopupUpdated', { row: item_data, index });
    },
    toEdit(row) {
      this.toEditFn(row);
    },
    async deleteRow(row) {
      let editting_row = this.getEdittingRow(row);
      editting_row.is_loading = true;
      await this.deleteRowFn(row).catch(err => {
        editting_row.is_loading = false;
        return;
      });
      editting_row.is_loading = false;
      this.getList();
      this.$emit('deleteRow', { row });
    },
    async openCreatePopup(default_data = null) {
      this.$refs.create_popup.open(default_data);
    },
    changePage(data) {
      this.page = data.currentPage;
      this.$emit('changePage', data.currentPage);
    },
    changeLimit(data) {
      this.limit = data.currentPerPage;
      this.$emit('changeLimit', data.currentPerPage);
    },
    colSort(data) {
      let sort = data[0] ? {...data[0]} : null;
      this.$emit('colSort', sort);
    },
    colFilter(filter) {
      let col_filter = omitBy(filter.columnFilters, (value) => value === '');
      this.query = { ...col_filter };
      this.$emit('colFilter', filter.columnFilters);
    },
    tableSearch(search) {
      this.$emit('tableSearch', search);
    },
    async exportData() {
      this.export_info.total = this.totalRows;
      this.export_info.is_exporting = true;
      let limit = this.export_info.total;
      if(limit > EXPORT_CHUNK_MAX) { limit = EXPORT_CHUNK_MAX }
      if(limit < EXPORT_CHUNK_MIN) { limit = EXPORT_CHUNK_MIN }

      await this.getExportData(1, limit);
    },
    async getExportData(page, limit) {
      if(!this.getListFn) { console.log('no_get-list-fn_for_table:', this.id); return }
      let { list, total } = await this.getListFn({
        limit,
        page,
        query: this.query,
      });
      this.export_info.data.push(...this.transformExportData(list));

      this.export_info.total = total;
      this.export_info.completed += list.length;

      if(list.length === limit) {
        return this.getExportData(page + 1, limit);
      } else {
        this.completeExportData();
      }
    },
    transformExportData(list) {
      return list.map(item => {
        let row_data = {};
        this.columns.forEach(col => {
          let field = col.field;
          let col_data;
          if(col.input_type === 'datetime') {
            col_data = this.$options.filters.local_time_string(item[field]);
          } else if(col.input_type === 'date') {
            col_data = this.$options.filters.local_date(item[field]);
          } else {
            col_data = item[field];
          }
          row_data[field] = col_data;
        });
        return row_data;
      });
    },
    completeExportData() {
      let headers = this.columns.map(col => col.label);
      sheet.exportData(this.export_info.data, headers, this.id);
      this.export_info.data.length = 0;
      this.export_info.completed = 0;
      this.export_info.total = 0;
      this.export_info.is_exporting = false;
    },
    transformTranslatableText(data){
      if (!data){
        return ''
      }
      let trans = data.translations.find((x)=>{
        return x.language == this.authLanguage
      })
      return trans ? trans.value : ""
    },
    styleMethod(column, row){
      if (column.styleMethod){
        return column?.styleMethod(row)
      }
      return ""
    },
    fieldClickEvent(field_name, data){
      this.$emit(`${field_name}_on_click`, data);
    },
    getProperty(obj, field){
      return get(obj, field)
    }
  }

}
</script>

<style>
[dir] table.vgt-table td {
  padding: 0.5em;
}
table.vgt-table tr:hover {
  background-color: #eee !important;
  color: #fff;
}

[dir] .vgt-table th {
  padding: 0.5em;
}

.clickable-field {
  color: #0075FF;
  cursor: pointer;
}
</style>
