<template>
  <div>
    <app-collapse type="shadow" class="mt-2 border p-1">
      <draggable :list="componentList" class="list-group list-group-flush ui-component-item" tag="ul"
        handle=".grab-button"
      >
        <transition-group type="transition" name="list">
          <app-collapse-item v-for="(item, itemIndex) in componentList" :key="item.id || item._id || itemIndex"
            class="drag-item my-1"
            :title="`${item.type}`" :is-visible="true"
          >
            <template slot="header">
              <div class="d-flex justify-content-between align-items-center flex-grow-1 w-100">
                <div class="d-flex align-items-center w-100 grab-button" style="cursor: grab;">
                  <feather-icon icon="TargetIcon" />
                  <span class="lead collapse-title ml-50 text-truncate d-block w-100" style="max-width: 90%">{{ titleByType[item.type] || item.type }}</span>
                </div>
                <div style="position: absolute; right: 30px; ">
                  <b-button variant="flat-danger" class="btn-icon rounded-circle"
                    @click.stop="removeComponent(itemIndex)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </div>
              </div>
            </template>
            <ui-component :key-component="item.id || item._id || itemIndex" :component-data="item" />
          </app-collapse-item>
        </transition-group>
      </draggable>

      <div v-if="!componentList.length" class="text-center"><span>No sentence, create them!</span></div>
    </app-collapse>

    <div class="text-center mt-1">
      <b-button @click="showLibrary" size="sm" pill variant="gradient-primary">
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        <span>Add New</span>
      </b-button>
    </div>
    <b-sidebar v-model="is_library_show"
      id="library-sidebar"
      shadow no-header
      bg-variant="white"
      backdrop
    >
      <ui-component-library @picked="addComponent" />
    </b-sidebar>
  </div>
</template>

<script>
import { BSidebar, VBToggle } from 'bootstrap-vue'
import draggable from 'vuedraggable'
import UiComponentLibrary from './UiComponentLibrary.vue'

export default {
  name: 'UiComponentList',
  components: {
    BSidebar,
    draggable,
    UiComponentLibrary,
  },
  directives: {
    VBToggle,
  },
  props: {
    componentList: { type: Array, required: true },
  },
  data() {
    return {
      is_library_show: false,
      titleByType: {
        'richtext': 'Rich Text'
      }
    }
  },
  methods: {
    addComponent(picked_component) {
      this.componentList.push(picked_component)
      this.is_library_show = false
    },
    removeComponent(index) {
      this.componentList.splice(index, 1)
    },
    showLibrary() {
      this.is_library_show = true
    },
  },
}
</script>

<style scoped>
.drag-item {
  transition: ease 0.6s
}
.ui-component-item {
  max-width: 800px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
</style>
