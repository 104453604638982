export default [
  {
    path: '/learning-gameplays',
    component: () => import('./views/Index'),
    name: 'learning_gameplay',
    redirect: { name: 'learning_gameplay.list' },
    children: [
      {
        path: '',
        name: 'learning_gameplay.list',
        component: () => import('./views/List'),
        meta: {
          resource: 'learning.learning_gameplay',
        },
      },
      {
        path: ':id',
        name: 'learning_gameplay.detail',
        component: () => import('./views/Detail'),
        meta: {
          resource: 'learning.learning_gameplay',
        },
      },
    ],
  },
]
