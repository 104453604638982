export default [
  {
    path: '/language',
    component: () => import('./views/Index'),
    name: 'language',
    redirect: { name: 'language-list' },
    children: [
      {
        path: '',
        name: 'language-list',
        component: () => import('./views/List'),
        meta: {
          resource: 'learning.language',
        },
      },
    ],
  },
]
