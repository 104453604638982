export default [
  {
    path: '/auth',
    component: () => import('./views/Index'),
    name: 'auth',
    redirect: { name: 'auth-profile' },
    children: [
      {
        path: 'login',
        name: 'auth-login',
        component: () => import('./views/Login'),
        meta: {
          layout: 'full',
        },
      },
      {
        path: 'forgot-password',
        name: 'auth-forgot-password',
        component: () => import('./views/ForgotPassword.vue'),
        meta: {
          layout: 'full',
        },
      },
      {
        path: 'profile',
        name: 'auth-profile',
        component: () => import('./views/Profile'),
        meta: {
          resource: 'auth',
        },
      },
      {
        path: 'change-password',
        name: 'auth-change-password',
        component: () => import('./views/ChangePassword.vue'),
        meta: {
          layout: 'full',
        },
      },
    ],
  },
]
