<template>
  <div>
    <app-collapse type="shadow" class="mt-2 border p-1">
      <draggable v-model="drag_items" class="list-group list-group-flush" tag="ul"
        handle=".grab-button"
      >
        <transition-group type="transition" name="list">
          <app-collapse-item v-for="(item, index) in items" :key="item.id || item._id || `{${index}}`"
            :title="getItemTitle(item)"
            :is-visible="checkVisible(items, index)"
          >
            <template slot="header">
              <div class="d-flex justify-content-between align-items-center flex-grow-1 w-100">
                <b-checkbox v-if="selectable_header && selectable_header_field" v-model="item[selectable_header_field]" :id="item.id || item._id || `{${index}}`"/>
                <div class="d-flex align-items-center w-100 grab-button" style="cursor: grab;">
                  <feather-icon icon="TargetIcon" />
                  <span class="lead collapse-title ml-50 text-truncate d-block w-100" style="max-width: 90%">{{ getItemTitle(item) }}</span>
                </div>
                <div style="position: absolute; right: 30px; ">
                  <b-button variant="flat-danger" class="btn-icon rounded-circle"
                    @click.stop="removeItem(index)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </div>
              </div>
            </template>
            <slot :item="item">
              {{ item }}
            </slot>
          </app-collapse-item>
        </transition-group>
      </draggable>

      <div v-if="!items.length" class="text-center"><span>No item!</span></div>
    </app-collapse>
    <div class="d-flex justify-content-center mt-50">
      <b-button v-if="enableCreateItem" variant="outline-success" block
        @click="addItem"
      >
        <feather-icon icon="PlusIcon" />
        Add
      </b-button>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { createUid } from '@core/utils/index'

export default {
  name: 'dynamic-zone',
  components: {
    draggable,
  },
  props: {
    items: { type: Array, default() { return []; } },
    title_field: { type: String, default: 'title' },
    defaultData: { type: Object, required: true },
    enableCreateItem: { type: Boolean, default: true },
    itemCollapse: { type: String, default: 'all' },
    computeTitleFn: { type: Function },
    selectable_header: { type: Boolean, default: false},
    selectable_header_field: {type: String, default: null}
  },
  data() {
    return {
      drag_items: [...this.items],
    };
  },
  watch: {
    drag_items() {
      this.items.length = 0;
      this.items.push(...this.drag_items);
    },
  },
  created(){
    this.drag_items = this.drag_items.map((item, index) => {return {...item, index: index}})
  },
  methods: {
    getItemTitle(item) {
      if(this.computeTitleFn && typeof this.computeTitleFn === 'function') {
        return this.computeTitleFn(item);
      }
      return item[this.title_field] || item.id || item._id
    },
    createDefaultItem() {
      let item = JSON.parse(JSON.stringify(this.defaultData));
      item.id = createUid();
      item.index = this.items.length;
      return item;
    },
    addItem(item_data = {}) {
      let item_default = this.createDefaultItem();
      let new_item = { ...item_default, ...item_data };
      this.items.push(new_item);
      this.drag_items.push(new_item);
    },
    removeItem(index) {
      this.items.splice(index, 1);
      this.drag_items.splice(index, 1);
      this.drag_items = this.drag_items.map((item, index) => {return {...item, index: index}})
    },
    checkVisible(items, index) {
      switch (this.itemCollapse) {
        case 'last':
          return index === items.length - 1;
          break;

        case 'first':
          return index === 0;
          break;

        default:
          return true;
          break;
      }
    },
  }
}
</script>
