import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import DataTableSsr from '@core/components/data-table-ssr/View.vue'
import DataCreatePopup from '@core/components/data-create-popup/View.vue'
import DyanamicZone from '@core/components/dynamic-zone/View.vue'
import MultiSelect from '@core/components/multi-select/View.vue'
import DatetimePicker from '@core/components/datetime-picker/View.vue'
import FormGenerator from '@core/components/form-generator/View.vue'
import UiComponent from '@core/layouts/components/input-fields/UiComponent.vue'
import UiComponentList from '@core/layouts/components/input-fields/UiComponentList.vue'
import ActionButtons from '@core/layouts/components/action-buttons/ActionButtons.vue'
import DeleteButton from '@core/layouts/components/action-buttons/DeleteButton.vue'
import UpdateButton from '@core/layouts/components/action-buttons/UpdateButton.vue'
import LoadingButton from '@core/layouts/components/action-buttons/LoadingButton.vue'
import PageBreadcrumb from '@core/layouts/components/PageBreadcrumb.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import TooltipButton from '@/modules/post/views/_components/TooltipButton.vue'
import LanguageSelect from '@/modules/language/views/_components/LanguageSelect.vue'
import RenderTranslatableText from '@core/layouts/components/input-fields/translatable-text-field/RenderTranslatableText.vue'
import { heightFade } from '@core/directives/animations'
import '@core/components/more-actions/index';
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(DataTableSsr.name, DataTableSsr)
Vue.component(DataCreatePopup.name, DataCreatePopup)
Vue.component(DyanamicZone.name, DyanamicZone)
Vue.component(MultiSelect.name, MultiSelect)
Vue.component(DatetimePicker.name, DatetimePicker)
Vue.component(FormGenerator.name, FormGenerator)
Vue.component(UiComponent.name, UiComponent)
Vue.component(UiComponentList.name, UiComponentList)
Vue.component(ActionButtons.name, ActionButtons)
Vue.component(DeleteButton.name, DeleteButton)
Vue.component(UpdateButton.name, UpdateButton)
Vue.component(LoadingButton.name, LoadingButton)
Vue.component(PageBreadcrumb.name, PageBreadcrumb)
Vue.component('AppCollapse', AppCollapse)
Vue.component('AppCollapseItem', AppCollapseItem)
Vue.component(TooltipButton.name, TooltipButton)
Vue.component(LanguageSelect.name, LanguageSelect)
Vue.component(RenderTranslatableText.name, RenderTranslatableText)
Vue.directive('height-fade', heightFade)

import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)
