import { computed } from '@vue/composition-api';
import ability from '@/libs/acl/ability'

export default function (route) {
  // computed
  const write_permission = computed(() => {
    let resource = route?.meta?.resource;
    if (resource) {
      return ability.can('write', resource);
    }
    return true;
  });

  return {
    write_permission,
  };
};
